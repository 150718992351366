import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/imagenes/barra-fija/Logo Aika Cree.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("nav", null, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "logo" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Logo Aika Cree"
        })
      ], -1)),
      _createElementVNode("div", {
        class: "icono",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("span", null, "☰", -1)
      ])),
      _createElementVNode("div", {
        class: _normalizeClass(['enlaces', _ctx.isOpen ? 'dos' : 'uno'])
      }, [
        _createVNode(_component_router_link, {
          to: "/",
          onClick: _ctx.closeMenu
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Inicio")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("a", {
          href: "https://creditos.aikacree.com/store-aika",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
        }, "Aika Store"),
        _createVNode(_component_router_link, {
          to: "/preguntas-frecuentes",
          onClick: _ctx.closeMenu
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Preguntas Frecuentes")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("a", {
          href: "#footer",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
        }, "Contáctanos"),
        _createElementVNode("a", {
          href: "https://creditos.aikacree.com/",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
        }, "Ingresa")
      ], 2)
    ])
  ]))
}