import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PreguntasFrecuentes from '../components/PreguntasFrecuentes/PreguntasFrecuentes.vue'
// import StoreAika from '../components/StoreAika/StoreAika.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Inicio',
    component: HomeView
  },
  {
    path: '/preguntas-frecuentes',
    name: 'preguntas-frecuentes',
    component: PreguntasFrecuentes
  },
  // {
  //   path: '/StoreAika',
  //   name: 'StoreAika',
  //   component: StoreAika 
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
